import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="June 2023" />
		<NewsHeader />
		<h2>June 2023</h2>

		<h3 className="blogdate">
			Friday, June 2, 2023
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">No Lights Shine Out to Me: new single</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				I'm stoked to get this one out there, into your ears!
				Find it at <Link to="/merch/">Bandcamp</Link>, or wherever you stream your music.
				Read all about it at my <Link to="/music/15/">music page</Link>.
			</p>

			<div className="main--centerwrapper p">
				<StaticImage
				src="../../images/catalog artwork/JRIVEST15 no lights front.jpg"
				alt="Jesse Rivest - No Lights Shine Out to Me - cover art"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>

		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;
